import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
       <path d="M4675 7220 c-10 -11 -14 -20 -9 -20 5 0 15 9 22 20 7 11 11 20 9 20
-2 0 -12 -9 -22 -20z"/>
<path d="M4573 7089 l-32 -41 46 -59 c26 -32 47 -59 49 -59 1 0 4 7 8 15 3 8
1 14 -4 12 -6 -1 -19 12 -29 28 -22 35 -13 82 14 71 8 -3 15 -1 15 4 0 13 -26
13 -52 -1 -28 -15 -36 -2 -10 17 12 8 22 21 22 29 0 7 6 16 13 18 6 3 7 6 2 6
-6 1 -25 -17 -42 -40z m2 -57 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9
12 -12z"/>
<path d="M4615 7008 c12 -38 24 -43 23 -9 -1 20 -6 31 -15 31 -10 0 -12 -7 -8
-22z"/>
<path d="M4660 6950 c0 -4 10 -15 23 -23 17 -13 19 -16 5 -16 -9 -1 -18 5 -21
12 -2 6 -8 9 -12 5 -8 -7 52 -103 90 -143 25 -27 48 -55 104 -128 l44 -58 24
23 23 24 -24 49 c-13 28 -27 61 -31 75 -4 14 -11 27 -15 30 -4 3 -17 34 -28
70 -15 51 -21 61 -27 46 -5 -11 -3 -26 3 -37 15 -23 16 -79 2 -79 -5 0 -10 18
-10 41 l0 41 -54 -4 c-35 -2 -52 1 -47 7 4 6 8 24 9 40 2 27 -1 30 -28 31 -17
1 -30 -2 -30 -6z m120 -160 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
4 10 6 0 11 -4 11 -10z m107 -82 c2 -17 0 -19 -7 -8 -5 8 -6 18 -4 22 8 12 10
10 11 -14z m24 -71 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z"/>
<path d="M4318 6759 c-5 -12 -80 -109 -170 -221 -184 -227 -184 -229 -145
-270 7 -7 28 -33 47 -58 19 -25 40 -52 47 -60 7 -8 35 -44 63 -80 124 -159
165 -210 170 -210 4 0 45 43 94 95 176 191 331 277 501 278 152 1 278 -70 425
-239 71 -82 119 -91 182 -35 l32 28 -35 18 c-106 54 -297 219 -414 359 l-40
48 -78 -12 c-201 -30 -384 57 -593 285 -54 59 -83 83 -86 74z"/>
<path d="M4769 5875 c-68 -87 -148 -188 -176 -224 -29 -36 -53 -70 -53 -76 0
-8 88 -126 153 -205 7 -8 35 -44 62 -80 27 -36 57 -74 67 -84 10 -11 28 -35
41 -53 12 -18 27 -33 32 -33 6 1 26 21 45 46 57 74 218 222 282 260 232 135
430 87 675 -166 30 -30 68 -74 86 -97 18 -23 35 -43 39 -43 4 0 33 35 65 78
32 42 60 79 64 82 3 3 34 43 69 90 36 47 86 111 112 144 l47 59 -27 36 c-15
20 -32 41 -38 46 -6 6 -32 37 -57 71 l-46 61 -101 12 c-56 7 -129 21 -162 32
-72 23 -82 23 -100 6 -49 -49 -141 -113 -206 -145 -75 -36 -80 -37 -182 -36
-92 1 -112 4 -164 28 -114 52 -218 139 -364 305 l-39 44 -124 -158z"/>
<path d="M2660 4900 c0 -47 4 -80 10 -80 6 0 10 -67 10 -180 l0 -180 80 0 80
0 0 260 0 260 -90 0 -90 0 0 -80z"/>
<path d="M2852 4903 l3 -78 108 -3 107 -3 0 -179 0 -180 80 0 80 0 0 180 0
180 80 0 80 0 0 80 0 80 -270 0 -271 0 3 -77z"/>
<path d="M3410 4900 c0 -77 1 -80 24 -80 22 0 23 -4 29 -94 6 -99 20 -140 66
-189 43 -48 98 -70 171 -71 74 -1 118 16 170 67 61 60 70 92 70 280 l0 167
-80 0 -80 0 0 -159 0 -160 -26 -20 c-30 -24 -52 -26 -87 -10 -40 18 -47 50
-47 204 l0 145 -105 0 -105 0 0 -80z"/>
<path d="M3960 4902 c0 -81 0 -81 28 -84 l27 -3 3 -177 2 -178 75 0 75 0 0
130 0 130 26 0 c18 0 41 -14 69 -43 43 -43 65 -94 65 -154 0 -59 5 -63 86 -63
l73 0 3 183 c2 100 2 217 0 260 l-3 77 -74 0 c-82 0 -88 -6 -85 -73 1 -17 2
-36 1 -40 -1 -5 -20 8 -43 27 -58 50 -136 77 -240 84 l-88 5 0 -81z"/>
<path d="M4510 4900 l0 -80 30 0 30 0 0 -180 0 -180 235 0 235 0 6 24 c3 13 4
48 2 78 l-3 53 -158 3 c-148 2 -157 4 -157 22 0 18 9 20 118 22 l117 3 0 55 0
55 -117 3 c-109 2 -118 4 -118 22 0 18 9 20 158 22 l157 3 0 75 0 75 -267 3
-268 2 0 -80z"/>
<path d="M5259 4962 c-60 -40 -79 -74 -79 -139 0 -65 27 -105 100 -152 33 -20
57 -41 53 -44 -3 -4 -67 -7 -140 -7 l-134 0 3 -77 3 -78 187 0 c174 0 189 1
215 21 72 53 94 144 53 211 -20 33 -87 88 -121 98 -47 15 -11 25 91 25 l110 0
0 80 0 80 -157 0 c-134 0 -162 -3 -184 -18z"/>
<path d="M5972 4903 c3 -77 3 -78 31 -81 l27 -3 0 -180 0 -179 123 0 c146 0
205 15 264 65 55 46 85 111 87 183 0 31 -2 69 -7 85 l-7 27 85 0 c47 0 85 -1
85 -2 0 -2 -26 -81 -58 -176 -32 -95 -56 -174 -54 -177 3 -3 40 -5 83 -5 l77
0 7 30 c7 33 2 31 130 31 30 0 59 0 64 0 5 -1 12 -14 16 -31 l7 -30 79 0 c56
0 79 4 79 13 0 6 -37 123 -82 259 l-83 248 -222 0 -223 0 0 -67 0 -67 -36 43
c-20 23 -58 53 -85 66 -46 24 -58 25 -219 25 l-171 0 3 -77z m328 -96 c49 -38
61 -89 33 -137 -20 -35 -52 -50 -103 -50 l-40 0 0 100 0 100 48 0 c26 0 54 -6
62 -13z m548 -86 c12 -38 22 -72 22 -75 0 -3 -22 -6 -50 -6 -27 0 -50 3 -50 7
0 15 44 143 50 143 3 0 15 -31 28 -69z"/>
<path d="M7014 4966 c-3 -7 -4 -42 -2 -77 l3 -64 108 -3 107 -3 0 -179 0 -180
80 0 80 0 0 180 0 180 131 0 c121 0 131 -1 124 -17 -7 -17 -88 -260 -105 -315
l-9 -28 80 0 c80 0 80 0 90 30 12 33 9 32 94 32 120 -1 113 0 120 -32 l7 -30
79 0 c56 0 79 4 79 13 0 6 -37 123 -82 259 l-83 248 -448 0 c-368 0 -449 -2
-453 -14z m825 -245 c11 -36 21 -69 21 -73 0 -10 -100 -11 -100 0 -1 19 46
150 51 144 4 -4 16 -36 28 -71z"/>
<path d="M4485 4422 c-11 -2 -31 -14 -44 -25 -32 -30 -101 -135 -101 -154 0
-9 -3 -13 -7 -10 -3 4 -11 0 -18 -9 -10 -15 -13 -15 -28 1 -9 9 -17 23 -17 30
0 8 -11 22 -25 31 -20 13 -31 14 -57 5 -27 -10 -34 -8 -53 9 -30 28 -71 26
-91 -6 -18 -27 -22 -27 -97 -8 -32 8 -50 -5 -83 -58 -50 -78 -18 -145 63 -134
23 3 45 1 48 -4 9 -14 32 -12 71 6 32 15 36 15 53 0 28 -25 81 -20 134 14 26
16 47 34 47 40 0 5 6 10 14 10 9 0 13 -10 12 -31 -2 -45 23 -58 68 -37 31 15
36 15 47 2 9 -11 23 -14 48 -10 44 8 55 8 107 0 23 -3 51 -1 62 5 15 8 27 7
45 -2 l25 -13 -21 -48 c-23 -54 -16 -79 22 -84 44 -7 79 33 95 108 4 18 16 30
40 38 19 7 53 31 75 52 32 32 41 47 41 74 0 40 -37 76 -76 76 -28 0 -28 0 -9
36 8 16 15 35 15 42 0 18 -50 62 -71 62 -23 0 -74 -44 -111 -94 -22 -30 -37
-41 -58 -42 -32 -2 -90 -25 -90 -36 0 -5 -7 -8 -15 -8 -21 0 -19 18 7 66 27
51 20 88 -19 101 -16 5 -37 7 -48 5z m46 -32 c23 0 6 -51 -31 -89 -32 -33 -36
-41 -21 -41 28 0 31 -20 8 -63 -11 -21 -17 -41 -13 -44 3 -3 30 17 60 45 80
76 136 90 136 36 0 -29 -30 -57 -67 -64 -28 -5 -45 -30 -20 -30 18 0 78 40 88
60 4 8 23 46 41 84 49 101 112 145 138 96 12 -22 2 -49 -34 -89 l-21 -23 22 6
c13 4 25 2 30 -6 4 -7 24 -13 43 -13 32 0 35 -3 38 -33 2 -22 -3 -41 -15 -54
-21 -23 -85 -58 -107 -58 -9 0 -20 -16 -27 -42 -18 -69 -27 -86 -49 -98 -28
-15 -37 10 -17 53 33 70 46 107 37 107 -5 0 -12 -4 -15 -10 -8 -14 -48 -13
-63 2 -9 9 -18 9 -37 0 -36 -17 -75 -15 -97 5 -17 15 -19 15 -37 -1 -11 -10
-30 -16 -43 -14 -20 3 -23 8 -22 46 0 23 -2 39 -6 35 -3 -4 -18 -25 -34 -45
-15 -21 -35 -38 -45 -38 -15 0 -18 7 -17 43 2 40 32 107 49 107 4 0 7 6 7 12
0 20 62 106 85 117 11 5 26 8 33 5 8 -2 18 -4 23 -4z m-419 -111 c15 -8 18
-17 13 -36 l-6 -25 28 19 c57 40 93 38 93 -3 0 -29 -36 -64 -66 -64 -13 0 -26
-5 -29 -11 -22 -34 49 -12 86 27 25 26 39 31 39 12 -1 -35 -72 -88 -116 -88
-35 0 -70 31 -58 52 4 7 -8 1 -28 -14 -38 -29 -93 -38 -100 -16 -3 8 -11 6
-29 -4 -17 -12 -31 -13 -48 -7 -21 8 -23 14 -18 48 6 51 34 91 61 91 36 0 36
-9 3 -56 -17 -24 -28 -47 -24 -50 3 -3 20 15 38 40 46 64 91 63 67 -2 -18 -47
-1 -51 38 -10 36 40 44 72 18 82 -8 3 -12 10 -9 16 8 13 21 13 47 -1z"/>
<path d="M4466 4322 l-19 -27 26 24 c28 26 32 31 20 31 -5 0 -17 -13 -27 -28z"/>
<path d="M4788 4331 c-18 -22 -38 -56 -38 -67 0 -9 34 35 49 64 14 27 11 28
-11 3z"/>
<path d="M4753 4219 c-26 -23 -43 -49 -43 -62 0 -19 6 -16 46 24 60 61 57 95
-3 38z"/>
<path d="M4845 4195 c-36 -37 -40 -51 -14 -40 19 9 63 65 55 72 -3 3 -22 -11
-41 -32z"/>
<path d="M5833 4396 c-16 -14 -37 -40 -47 -58 -14 -25 -31 -39 -65 -51 -26 -9
-60 -30 -77 -46 -28 -28 -30 -29 -56 -13 -14 8 -29 24 -31 33 -3 10 -17 24
-31 31 -22 9 -29 9 -42 -4 -11 -11 -28 -14 -53 -11 -26 4 -41 0 -50 -11 -12
-14 -16 -13 -43 9 -35 29 -40 30 -79 10 -21 -12 -34 -13 -48 -5 -28 14 -57 12
-71 -5 -9 -11 -17 -12 -32 -4 -30 16 -60 3 -90 -40 -30 -42 -36 -89 -16 -121
11 -16 9 -23 -14 -47 -50 -53 -48 -101 4 -126 53 -26 101 4 146 92 32 61 47
74 71 61 26 -13 78 -13 87 1 4 7 21 9 48 5 22 -4 70 -9 106 -11 61 -4 68 -2
104 27 34 27 40 29 48 14 13 -22 63 -47 83 -40 10 4 23 -3 36 -20 25 -32 66
-34 99 -6 13 11 31 20 41 20 10 0 20 4 23 9 3 4 19 11 35 14 16 4 46 21 67 39
l37 33 -5 -30 c-12 -60 32 -85 83 -47 29 21 73 21 97 0 19 -17 168 -23 187 -8
6 5 27 11 47 15 30 6 39 4 44 -9 7 -19 74 -22 92 -4 9 9 15 9 24 0 15 -15 84
-16 93 -2 8 12 52 13 59 1 7 -10 106 -9 123 2 6 4 23 2 37 -4 22 -10 29 -9 46
6 26 24 33 50 20 74 -9 18 -19 20 -86 17 -83 -4 -100 5 -124 64 -18 44 -48 55
-97 35 -44 -18 -83 -9 -83 19 0 46 -70 40 -113 -9 -22 -26 -23 -27 -53 -9 -30
17 -32 17 -68 -4 -32 -19 -42 -20 -84 -11 -106 24 -202 17 -202 -14 0 -7 -7
-21 -15 -31 -14 -19 -15 -19 -50 0 -20 10 -43 29 -51 42 -13 20 -13 25 1 44
22 32 20 85 -4 98 -33 17 -78 11 -108 -14z m88 -6 c23 0 6 -53 -28 -91 -31
-34 -34 -41 -16 -35 15 5 27 1 42 -15 24 -26 26 -41 10 -72 -10 -22 -10 -22
10 1 19 21 51 30 51 14 0 -16 -56 -60 -85 -66 -83 -19 -84 -19 -96 7 -14 31
-5 71 21 97 11 11 16 20 12 20 -16 0 -46 -40 -59 -78 -18 -51 -16 -70 5 -68
32 2 19 -29 -15 -32 -36 -4 -50 20 -38 66 l7 27 -24 -22 c-13 -11 -32 -23 -44
-25 -25 -4 -54 18 -54 41 1 37 78 99 130 103 27 2 34 8 47 40 18 45 79 102
100 94 8 -3 19 -6 24 -6z m625 -105 c-6 -22 -3 -25 19 -25 32 0 31 -5 -5 -59
-35 -52 -30 -70 7 -26 47 54 94 85 129 85 29 0 34 -3 34 -24 0 -28 -46 -66
-79 -66 -12 0 -21 -6 -21 -15 0 -27 42 -13 89 31 27 25 46 23 32 -4 -30 -56
-109 -87 -157 -62 -19 11 -27 11 -36 2 -18 -18 -65 -15 -72 5 -4 9 2 34 13 57
26 50 20 57 -10 14 -42 -60 -41 -59 -145 -85 -11 -2 -27 2 -35 10 -13 12 -18
13 -34 1 -42 -31 -84 -8 -70 37 4 11 2 19 -4 19 -6 0 -11 -4 -11 -10 0 -13
-38 -32 -50 -25 -7 4 -6 13 0 26 7 13 7 19 0 19 -5 0 -10 -4 -10 -9 0 -16 -58
-71 -75 -71 -13 0 -16 5 -10 23 4 12 6 32 6 44 0 12 4 25 10 28 6 4 14 18 20
32 10 28 45 28 43 0 -2 -15 1 -16 15 -5 9 7 19 17 22 21 9 13 54 4 52 -10 -2
-8 9 -10 32 -6 41 7 41 5 14 -48 -35 -70 -19 -61 60 32 40 47 76 55 98 23 9
-14 13 -35 10 -52 -5 -27 -5 -27 24 8 16 19 29 44 29 56 0 22 34 53 59 53 9 1
11 -6 7 -24z m-1229 -23 c7 -4 16 -26 21 -47 l8 -40 25 37 c16 25 32 38 47 38
27 0 28 -17 2 -50 -11 -14 -20 -31 -20 -39 0 -20 35 13 62 58 27 44 35 50 59
44 21 -6 24 -14 10 -28 -17 -17 -42 -85 -31 -85 5 0 22 14 37 30 31 35 43 38
43 11 0 -22 -66 -81 -91 -81 -9 0 -22 7 -29 15 -9 11 -16 13 -24 5 -15 -15
-66 -12 -72 5 -4 9 -11 11 -22 6 -10 -5 -36 -15 -59 -22 -45 -13 -73 -3 -73
27 0 8 -20 -6 -45 -32 -25 -25 -45 -50 -45 -57 0 -21 -54 -89 -78 -98 -30 -12
-62 5 -62 33 0 21 90 118 109 118 5 0 11 7 15 15 5 14 2 14 -24 0 -54 -28 -83
9 -54 70 9 20 26 42 37 50 19 13 23 13 36 0 13 -13 12 -18 -12 -43 -35 -36
-37 -65 -2 -32 13 13 35 38 47 56 28 41 68 47 68 11 0 -13 -5 -28 -12 -35 -6
-6 -9 -14 -5 -18 3 -3 20 11 38 31 28 31 58 52 79 54 3 0 11 -3 17 -7z m1483
-122 c0 -24 -26 -38 -42 -22 -8 8 -8 15 2 27 18 21 40 19 40 -5z m68 -2 c-5
-34 -38 -37 -38 -4 0 20 5 26 21 26 15 0 20 -5 17 -22z m82 2 c0 -24 -26 -38
-42 -22 -16 16 -2 42 22 42 13 0 20 -7 20 -20z"/>
<path d="M5862 4327 c-12 -13 -22 -27 -22 -32 0 -4 11 4 25 19 23 25 30 36 22
36 -2 0 -13 -10 -25 -23z"/>
<path d="M5695 4194 c-20 -20 -34 -39 -30 -42 3 -3 25 13 48 36 23 23 36 42
30 42 -7 0 -29 -16 -48 -36z"/>
<path d="M5861 4194 c-22 -28 -28 -54 -12 -54 12 0 51 54 51 69 0 19 -18 12
-39 -15z"/>
<path d="M6362 4205 c-24 -30 -30 -67 -9 -60 15 6 40 61 35 77 -2 6 -14 -1
-26 -17z"/>
<path d="M5278 4213 c-26 -30 -39 -61 -28 -68 14 -9 27 1 45 37 18 36 6 57
-17 31z"/>
<path d="M5029 4014 c-13 -15 -18 -25 -10 -22 13 4 49 48 39 48 -2 0 -15 -12
-29 -26z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
